.mainFooter {
  display: none;
  text-align: center;
  height: 16px;
  line-height: 16px;
  .poweredBy {
    display: inline-block;
    color: #b6b7b9;
    font-size: 11px;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
    a {
      margin: 0 0 0 5px;
      color: var(--primary-color);
      font-size: inherit;
    }
  }
}

.main-table-container,
main > {
  .mainFooter {
    .poweredBy {
      background: none;
    }
  }
}
