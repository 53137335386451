.chartFlexItem {
  margin-bottom: 10px;
  flex: 1 0 300px;
  position: relative;
  overflow: hidden;

  div {
    height: 100%;
  }
}

.chartParent {
  height: 100%;
  width: 100%;
  position: absolute;
}

.dashboardApp {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  text-align: center;
  background-color: #f8f8f8;
}
