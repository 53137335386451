.employee-add-prealoder-container {
  height: 168px;
  display: flex;
  align-items: center;
}

.employee-delete-prealoder-container {
  height: 158px;
  display: flex;
  align-items: center;
}

.user-add-prealoder-container {
  height: 245px;
  display: flex;
  align-items: center;
}

.br-crump, .br-crump:hover {
    color: var(--primary-color) !important;
}

.table-details-user-info-value-padding {
  padding: 0 0 0 25px;
}

.email-text-td-additional-styles {
  letter-spacing: 0.48px;
  color: #68b0ee !important;
  cursor: pointer;
  font-size: 16px !important;
  font-weight: 400px !important;
}

.m-badge--wide-additional-styles {
  border: none;
  font-size: 15px;
  margin-left: 15px;
}