.maintenance-container {
    color: #fff;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #fff;

    h2 {
        color: #161B2B;
        font-size: 24px;
        margin-top: 20px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    span {
        font-size: 16px;
        font-weight: 500;
        color: #161B2B7F;
    }

    &>span:nth-child(4) {
        margin-bottom: 10px;
    }

    #big-bottom-circle {
        fill: #f9f9fa;
    }

    #big-bottom-gear, #path264 {
        fill: #8d9199;
    }

    #little-bottom-circle {
        fill: #f2f2f3;
    }

    #right-bottom-gear{
        fill: #ebebed;
    }

    #right-top-gear {
        fill: #c8c7cc;
    }

    #left-top-little-gaer {
        fill: #e7ebee;
    }

    #left-top-big-gear {
        fill: #c8c7cc;
    }
}