@import "./style.bundle.css";
@import "./vendors.bundle.css";
// Required for compatibility with old icons
@import "./line-awesome-old.scss";

$deviceActivityWarningColor: #ff9e22;

input::-ms-clear {
  display: none;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  background-color: #f8f8f8 !important;
  font-family: Poppins !important;
  overflow: visible !important;
  padding-right: 0 !important;
}

main {
  display: flex;
  background-color: #f8f8f8;
  flex-direction: column;
  height: 100%;
}

ul {
  list-style-type: none;
}

a {
  cursor: pointer;
  color: var(--primary-color);
}

hr {
  background-color: #dfdfdf;
}

input {
  color: #161b2b !important;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.48px;
  box-shadow: none !important;
}

button:disabled {
  cursor: default;
}

select {
  &:focus {
    outline: none;
  }

  option {
    color: #000;
  }
}

.smallerCur {
  font-size: 0.8em;
}

.zIndex19 {
  position: relative;
  z-index: 19;
}

.zIndex20 {
  position: relative;
  z-index: 20;
}

.zIndex21 {
  position: relative;
  z-index: 21;
}

.btn-settings {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  border-radius: 15px;
  letter-spacing: 1.4px;
  border: 1px solid #fff !important;
  background: transparent !important;
  box-shadow: none !important;
  width: 83px;
  line-height: 0.5;

  &.btn-popup-canc {
    line-height: 0.9;
    width: auto;
    height: 39px;
    border: 1px solid #959ba2 !important;
    opacity: 0.9;
    color: #9e9e9e !important;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.64px;

    &:hover {
      border: 1px solid #9e9e9e;
      color: #fff;
      background-color: #9e9e9e;
    }
  }
}

.input-field.devices-checkbox-parent {
  height: auto !important;
  margin-bottom: 14px !important;
  margin-top: 40px;

  &:nth-last-child(1) {
    margin-bottom: 25px !important;
  }

  .switch-tooltip {
    margin-top: -3px;
  }
}

.btn {
  display: inline-block;
  line-height: 1.25;
  transition: none;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 0.65rem 1rem;

  &:disabled {
    opacity: 1;
  }
}

.btn.btn-secondary {
  i.fa-paper-plane {
    color: var(--primary-color);
    margin-right: 10px;
    font-size: 16px;
  }

  i.fa-save {
    color: var(--primary-color);
    margin-right: 2px;
    margin-bottom: 2px;
    font-size: 16px;
  }

  i.fa-trash {
    color: var(--primary-color);
    margin-left: -3px;
    margin-bottom: 4px;
    font-size: 20px;
  }

  i.fa-archive {
    color: var(--primary-color);
    margin-left: -3px;
    margin-bottom: 1px;
    font-size: 18px;
  }
}

.button {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 48px;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 20px;
  line-height: 22px;
  font-style: normal;
  text-wrap: nowrap;

  span {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.button-primary {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    color: #fff;

    &:hover {
      filter: brightness(1.1);
    }
  }

  &.button-secondary {
    background-color: transparent;
    color: #161b2b;
    border: 1px solid var(--primary-color);

    &:hover {
      background-color: var(--primary-color);
      color: #fff;

      svg {
        fill: #fff;
      }

      i {
        color: #fff;
      }
    }
  }

  &.button-third {
    border: none;
    background-color: transparent;
    color: var(--primary-color);


    &:hover {
      text-decoration: underline;
    }
  }

  &.button-fourth {
    border: 1px solid var(--primary-color);
    background-color: transparent;
    color: #000;

    &:hover {
      background-color: var(--primary-color);
      color: #fff;

      svg {
        fill: #fff;
      }

      i {
        color: #fff;
      }
    }
  }

  &.button-delete {
    background-color: transparent;
    border: 1px solid #F11D1D;
    color: #F11D1D;

    &:hover {
      background-color: #F11D1D;
      color: #fff;
    }
  }

  &.with-icon {
    padding-left: 16px;
  }

  &.button-small {
    padding: 4px 20px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

i.fa {
  font-size: 14px;
}

i.flaticon-event-calendar-symbol {
  display: inline-block;
  font-size: inherit;
  text-rendering: auto;
}

i.fa-cog {
  font-size: 12px;
}

.user-name-user-icon:hover .btn.m-btn--hover-accent {
  &.m-btn--hover-accentik {
    border-color: var(--primary-color) !important;
    background-color: var(--primary-color) !important;
    color: #fff !important;

    i.la-user {
      color: #fff !important;
      border-color: var(--primary-color) !important;
      background-color: var(--primary-color) !important;
    }
  }

  .la-cog {
    background-color: white !important;
    border-color: none !important;
  }
}

.pvInstallerAddBtn {
  /*  hack with add button in PV_INSTALLER PAGE */
  right: 0;
  position: absolute;
  margin-top: -108px;
}

.OEMAddBtn {
  right: 20px;
  position: absolute;
  margin-top: -49px;
}

.navig-btn-container {
  margin-top: 14px;
  margin-bottom: 14px;
}

.navig-btn-dashb {
  margin-right: 9px;
  width: 41px;
  height: 25px;
  border-radius: 4px;
  border: 1px solid rgba(19, 19, 19, 0.50);
  background-color: transparent;
  color: #131313;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    background-color: #131313;
    color: #fff;
  }

  &.btn-today {
    width: auto !important;
    min-width: 78px;
  }

  &.btn-plus-zoom-dashb {
    margin-right: 0;
    border-radius: 4px 0 0 4px;
  }

  &.btn-min-zoom-dashb {
    border-radius: 0 4px 4px 0;
    border-left: none;
  }

  &.btn-calendar-dashb {
    border: none;
    padding: 0;
    width: auto;
    font-size: 1.4em;

    &:hover {
      background: none;
      color: #000;
    }
  }
}

.btn.m-btn--pill {
  &.m-btn--air.btn-secondary {
    &.btn-table-inst {
      background: transparent;
      border: 1px solid var(--primary-color) !important;
      border-radius: 19px;
      font-size: 14px;
      font-weight: 400;
      color: var(--primary-color);
      box-shadow: none !important;
      line-height: 0.5;

      &:disabled {
        color: #fff;
        border-color: #ffb822;
        opacity: 0.5;
      }

      &.send-log-request {
        line-height: 12px;
        font-size: 0.77em;
        text-transform: none;
      }

      &.exportBtn {
        line-height: 12px;
        font-size: 0.9em;
        text-transform: none;
      }

      &.btn-inp-file-choose {
        border-radius: 0 3px 3px 0;
        line-height: 30px;
        padding: 3px 0;
      }

      &.btn-popup-sav {
        line-height: 0.9;
      }

      &.btn-save-chan {
        line-height: 0.7;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1.4px;
      }

      &.btn-info-user.btn-popup-sav {
        height: 39px;
        opacity: 0.9;
        color: var(--primary-color);
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.64px;
        background: transparent;
        border: 1px solid var(--primary-color) !important;
        border-radius: 19px;
        box-shadow: none !important;
        line-height: 0.5 !important;
      }

      &.btn-comp.btn-attach {
        border-radius: 0 3px 3px 0;
        line-height: 30px;
        margin-top: -3px;
        margin-left: 0;
      }

      .fa-save {
        color: var(--primary-color) !important;
      }

      &.delete-btn {
        color: #f53920 !important;
        border: 1px solid #f53920 !important;
        text-transform: capitalize;

        &:hover:hover:not(:disabled):not(.active),
        &:hover {
          background-color: #f53920 !important;
          border: 1px solid #f53920 !important;
          color: #fff !important;
        }
      }

      &.filter {
        border: 1px solid #161B2B !important;
        color: #161B2B !important;

        i.fa-filter {
          font-size: 16px;
          color: #161B2B !important;
        }

        &:hover:hover:not(:disabled):not(.active),
        &:hover {
          background-color: transparent !important;
          color: #161B2B !important;
          border: 1px solid #161B2B !important;
        }
      }
    }
  }
}

.btn.m-btn--pill.btn-metal.m-btn.m-btn--custom.m-btn--label-brand.m-btn--bolder {
  background-color: transparent;
  color: #4b8bb9;

  &:hover {
    border: 1px solid #4b8bb9 !important;
    background-color: #4b8bb9 !important;
    color: #fff !important;
  }
}

.change-btn,
.resend-btn {
  position: absolute;
  margin: 0;
  padding: 0;
  cursor: pointer;
  top: 50px;
  background-color: transparent;
  border: none;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1.12px;

  &:hover {
    color: var(--primary-color);
    text-decoration: underline;
  }
}

.change-btn {
  right: 0;
}

.resend-btn {
  left: 0;
}

.navig-btn-dashb-act {
  background-color: #131313;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}

.bootstrap-select>.dropdown-toggle.btn-light:focus {
  outline: none !important;
}

.m-btn--icon.m-btn--icon-only.m-btn--icon-dashb [class*=" la-cog"],
.m-btn--icon.m-btn--icon-only.m-btn--icon-dashb [class*=" la-user"] {
  font-size: 1.6rem;
}

.btn-focus.m-btn--air,
.btn-outline-focus.m-btn--air,
.m-btn--gradient-from-focus.m-btn--air {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.btn.m-btn--air.btn-default,
.btn.m-btn--air.btn-secondary {
  border: 1px solid #9e9e9e !important;
  color: #9e9e9e;
}

.btn-sm-us {
  padding-left: 0;
}

.cont-for-canc-btn {
  padding-left: 0;
}

.emp-set-btn-canc {
  height: auto;
  line-height: 0.7;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1.4px;
}

.form-group.m-form__group.input-field.input-field-for-change-btn {
  position: relative;
}

.btn.m-btn--air.btn-secondary.btn-table-inst:hover:hover:not(:disabled):not(.active) {
  background: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  color: #fff;
  box-shadow: none !important;
}

.btn.m-btn--hover-accent.trash {

  &:hover,
  &:focus,
  &:active {
    border-color: red !important;
    background-color: red !important;
  }
}

.btn.m-btn--air.btn-secondary.btn-settings.btn-popup-canc {
  &:hover:not(:disabled):not(.active) {
    border: 1px solid #9e9e9e !important;
    background-color: #9e9e9e !important;
    color: #fff !important;
  }

  &:focus {
    background-color: #d8d8d8 !important;
    border: 1px solid #d8d8d8 !important;
    color: #000000 !important;
  }
}

.btn.m-btn--air.btn-secondary.btn-settings:hover:not(:disabled):not(.active),
.btn.m-btn--air.btn-secondary.btn-settings:focus {
  background: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
}

.btn-inp-file-choose {
  width: 25%;
  min-width: 160px;

  @media (max-width: 500px) {
    width: 100%;
  }
}

.btn.m-btn--hover-accent:hover {
  border-color: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
}

a.nav-link.tab-heading {
  cursor: pointer;
}

.nav-pills {
  .nav-link.active {
    background-color: #fff;
    color: black;
  }

  .show>.nav-link {
    background-color: #fff;
  }
}

.nav.nav-pills .nav-link.active {
  color: black;
}

.m-tabs-line.m-tabs-line--danger.nav.nav-tabs .nav-link.active,
.m-tabs-line.m-tabs-line--danger.nav.nav-tabs .nav-link:hover {
  color: var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
}

.nav-link.tab-heading {
  background-color: rgba(255, 255, 255, 0.25);
  color: #ffffff !important;
  font-size: 14px;
  font-weight: 600 !important;
  letter-spacing: 0.42px;
  box-sizing: border-box;
  border-radius: 0;
  padding: 15px;

  &.active {
    background-color: #fff;
    color: #000000 !important;
  }
}

.react-tabs__tab--selected .nav-link.m-tabs__link {
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color);
}

.react-tabs__tab .m-tabs-line.m-tabs-line--danger.nav.nav-tabs .nav-link:hover {
  border-bottom: 4px solid var(--primary-color);
}

.react-tabs__tab .nav-link.m-tabs__link {
  min-height: 55px;
  cursor: pointer;
}

.m-tabs-line.nav.nav-tabs .nav-link {
  color: #4a4a4a;
  letter-spacing: 0.48px;
  font-weight: 500;
  font-size: 18px;
}

.nav-link.m-tabs__link:focus {
  outline: none;
}

.Select-control {
  background: transparent !important;
  border: none !important;
  border-bottom: 1px solid #9e9e9e !important;
  border-radius: 0 !important;

  &:hover {
    box-shadow: none !important;
  }

  .Select-input:focus {
    background: transparent !important;
  }
}

.react-select-container {
  font-size: 14px;

  .react-select__control {
    box-shadow: none !important;
    background: transparent;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 0;

    &:hover {
      border-bottom: 1px solid #5e6774;
    }

    .react-select__value-container {
      white-space: nowrap;
      padding-left: 0 !important;
      font-weight: 400;
      color: #161b2b;
      letter-spacing: 0.48px;

      .react-select__placeholder,
      .react-select__single-value {
        color: #161b2b;
      }
    }

    .react-select__indicators {
      .react-select__indicator {
        padding-right: 0;
        color: #5e6774;

        &:hover {
          color: #5e6774;
        }
      }

      .react-select__indicator-separator {
        display: none;
      }
    }

    &.react-select__control--menu-is-open {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .react-select__menu {
    z-index: 11;
    color: #212529;

    .react-select__menu-list {
      border: none;
      border-radius: 3px;
      max-height: 160px;
    }

    .react-select__option {
      padding: 2px 5px;
      font-weight: 400;

      &:hover,
      &.react-select__option--is-focused,
      &.react-select__option--is-selected {
        background-color: #eaeaea;
        color: #212529;
      }
    }
  }

  &.react-select--is-disabled {
    .react-select__indicators {
      opacity: 0.5;
    }
  }
}

.battery-mode-btn-wrapper {
  display: flex;
  justify-content: end;

  button {
    padding: 3px 0 0 0;
  }
}

.form-group.m-form__group.input-field {
  .customTextField .inputRoot.MuiInput-disabled-18 {

    &:before,
    &:after {
      border-bottom: 1px solid #9e9e9e !important;
      line-height: 1.1876em;
    }
  }

  div.m-input {
    width: 100% !important;
  }

  .inputRoot.MuiInput-disabled-18:before,
  .inputRoot.MuiInput-disabled-18:after {
    border-bottom: 1px solid #9e9e9e !important;
  }

  &.city-inp,
  &.zip-inp {
    padding: 0;
    margin-top: 14px !important;
  }

  &.city-inp.city-inp-comp-set,
  &.zip-inp.zip-inp-comp-set {
    padding: 0;
    margin-top: 0 !important;
  }
}

.m-form .m-form__group {
  padding-top: 0;
  padding-bottom: 0;

  .form-control-label,
  label {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.39px;
  }

  &>.label_for_select {
    margin-bottom: 0;
    font-size: 13px;
  }
}

.flag-select {
  color: #7c7c7c !important;
  text-align: left;
  width: 100%;

  .country-flag .country-label {
    font-size: 14px !important;
    line-height: 18px;
    font-weight: 400;
    letter-spacing: 0.48px;
  }

  img {
    top: 0 !important;
  }

  .selected--flag--option {
    cursor: pointer;
    padding: 0 !important;
    position: relative;
  }

  .arrow-down:hover {
    border-color: #666 transparent transparent !important;
  }

  &.flagSelectClosed .arrow-down {
    color: #999 !important;
    padding: 0 !important;
    position: absolute;
    right: 9px;
    overflow: hidden;
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px;
    display: inline-block;
    height: 0;
    width: 0;
    border-spacing: 0;
    border-collapse: separate;
    text-align: center;
    box-sizing: border-box;
    top: 5px;
  }
}

.gener-sett-inside-cont {
  margin-top: 15px;

  &.smu-set-bl {
    margin-top: 0;
    border: none;
    border-right: 1px solid #40424d;

    &.smu-set-bl-last {
      border-right: none;
    }
  }
}

.m-dropdown__header.m--align-center {
  background: url(../../../public/images/bg-img.png);
  background-size: cover;
  background-position: center;
}

.m-dropdown__wrapper {
  width: 325px;

  .m-dropdown__header {
    padding: 20px 20px;
    border-radius: 4px 4px 0 0;
  }

  .m-dropdown__body {
    padding: 20px;
    background-color: #fff !important;
  }
}

.m-dropdown__arrow.m-dropdown__arrow--right.m-dropdown__arrow--adjust {
  position: absolute;
  top: -11px;
  color: #494d5a;
  right: 2.5px !important;
  line-height: 0;
  display: inline-block;
  overflow: hidden;
  height: 11px;
  width: 40px;
  left: 50%;
  margin-left: -20px;
}

.m-dropdown__arrow:before {
  display: inline-block;
  font-family: Metronic, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  line-height: 0;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  content: "";
  position: relative;
  top: 100%;
  margin-top: 11px;
  font-size: 40px;
}

.dropdown-menu.dropdown-menu-right.show {
  position: absolute;
  transform: translate3d(-196px, 27px, 0px);
  top: 0px;
  left: 0px;
  will-change: transform;
}

.dropdown-item {
  &.dropdown-item-dashb {
    padding: 0.25rem 1rem;
  }
}

@keyframes showNoJSWarning {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

.noJavascriptWarning {
  font-size: 18px;
  color: var(--primary-color);
  display: inline-block;
  max-width: 100%;
  box-sizing: border-box;
  padding: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation-name: showNoJSWarning;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-delay: 12s;
  animation-fill-mode: both;
}

.loadingTextWithBG {
  background: #f8f8f8;
  color: black;
  font-size: 2em;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.05s ease-out;
  opacity: 0;
  visibility: hidden;

  &.loading {
    opacity: 1;
    visibility: visible;
  }
}

.uploadFileName {
  max-width: 100%;
  margin-top: 35px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.6);
  position: absolute;
  padding: 5px 15px 0 15px;
  box-sizing: border-box;
}

.marginBottom {
  margin-bottom: 15px;
}

.marginBottom6px {
  margin-bottom: 6px !important;
}

.QRScannerBOption {
  padding: 10px 0;
  font-size: 16px;
  color: white;
  opacity: 0.6;

  .bOptionButton {
    display: inline-block;
    line-height: 20px;
    padding: 0 5px;
    cursor: pointer;
    text-decoration: underline;
    font-size: 18px;
    color: var(--primary-color);

    &:hover {
      color: #fff;
    }
  }
}

.mainPreLoaderParent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  min-height: 158px;

  .mainPreLoader {
    height: 70px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.Select-input {
  padding-left: 0 !important;
}

.firstLetterToUpCase:first-letter {
  text-transform: uppercase;
}

.whiteStatusText {
  color: rgba(255, 255, 255, 0.7);
  font-size: 20px;
  margin: 10px 15px;
}

.curr-page:hover {
  cursor: initial !important;
  background-color: initial !important;
}

.topbar-dashb {
  background-color: #fff;
}

.smu_pvi {
  margin-top: 45px;
}

.m-card-user.m-card-user--skin-dark .m-card-user__details .m-card-user__email {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 216px;
  color: #cbccd8;
}

.loadingText {
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-size: 1.5em;
  margin-top: 50px;
}

.user-name-user-icon:hover .user-name-topbar {
  cursor: pointer;
  text-decoration: underline;
  color: var(--primary-color) !important;
}

.m-grid__item.m-grid__item--fluid.m-login__wrapper {
  align-items: center;
}

.Select-option {
  font-size: 14px !important;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: 0.48px;
  padding: 0px 20px 3px 8px;
  margin: 4px 0;

  &.is-focused {
    background-color: #eaeaea !important;
  }
}

.Select-menu {
  position: absolute;
  z-index: 999999;
  border: 1px solid #bdbbbb;
  border-radius: 3px;
  background: #ffffff;
  margin-top: 8px;
  padding: 8px 0;
  max-height: 160px;
  overflow: auto;
  width: 100%;
}

.flags-select-label {
  margin-bottom: 0;
  color: var(--primary-color);
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.39px;
}

.progress-energy-percent {
  flex-grow: 0;
  padding-left: 0;
  padding-right: 0;
  opacity: 0.9;
  color: #a7a8b2;
  font-size: 16px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.22px;
}

.la-angle-left.la-angle-left-stat.la,
.la-angle-right.la-angle-right-stat.la {
  color: #c6c8d2;
  font-size: 1.2rem;
  cursor: pointer;
  position: absolute;
  top: 50%;
  z-index: 100;
  margin-top: -20px;
  padding: 10px 10px;
}

.la-angle-right.la-angle-right-stat.la {
  right: 5px;

  &:hover {
    color: #161b2b;
  }
}

.la-angle-left.la-angle-left-stat.la {
  left: 5px;

  &:hover {
    color: #161b2b;
  }
}

.sm-en-cont {
  padding: 5px 0;
  position: relative;
}

.en-stat-data-h {
  padding-top: 12px;
  padding-bottom: 5px;
}

select.form-control.car-charger-input:not([size]):not([multiple]) {
  height: calc(2.39rem + 6px);
  cursor: pointer;
}

.m-checkbox.m-checkbox--light>input:checked~span {
  border: 1px solid var(--primary-color) !important;
}

/* !Fix checkbox orange */
.m-checkbox.m-checkbox--light>span:after {
  border: solid var(--primary-color);
}

.m-checkbox.m-checkbox--light.checkbox-terms,
.m-checkbox.m-checkbox--light.checkbox-terms a {
  color: var(--primary-color) !important;
  font-weight: 500 !important;
}

input[type="text"]:not(.browser-default):focus:not([readonly]),
input[type="tel"]:not(.browser-default),
input[type="text"]:not(.browser-default),
input[type="password"]:not(.browser-default),
input[type="password"]:not(.browser-default):focus:not([readonly]) {
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.48px;
  box-shadow: none;
}

input[type="text"]:not(.browser-default):focus {
  border: none !important;
}

.user-name-icon {
  cursor: pointer;
}

.user-name-user-icon {
  display: flex;
  flex-direction: justify;
}

.user-name-topbar {
  margin-right: 8px;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
}

.la.la-user.la-user-comp:hover {
  background-color: var(--primary-color);
  cursor: pointer;
}

div[class*="MuiPaper-root-"] {
  background: transparent !important;
}

.m-nav .m-nav__item>.m-nav__link {
  .m-nav__link-icon {
    color: #0662ab;
  }

  .m-nav__link-text {
    color: #161b2b;
  }
}

.m-nav__link:hover {
  .m-nav__link-icon {
    color: #0662ab !important;
  }

  .m-nav__link-text {
    color: #0662ab !important;
  }
}

.m-nav .m-nav__separator {
  border-bottom: 1px solid #f5f4f5;
}

.en-values-container {
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
  padding-top: 0;
  padding-bottom: 0;
}

.is-clearable.is-searchable {
  border: none !important;
}

.table-details-user-nifo {
  white-space: nowrap;
  color: #161B2B;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.48px;
}

.table-tariff-header {
  white-space: nowrap;
  height: 34px;
}

.dev-nav-prior {
  border: 1px solid rgba(255, 255, 255, 0.15);
  padding: 8px 14px;
  border-radius: 2px !important;
  margin: 0 5px;
  text-align: right;
  transition: all 0.2s ease-in;
}

.priority-column {
  position: relative;
}

.dev-nav-arrow {
  display: inline-block;
  text-align: right;
  position: absolute;
  right: 5px;
}

.form-control.m-input--air:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.input-field {
  height: 57px !important;
  margin-bottom: 20px !important;
}

.input-field.note-input-field {
  height: auto !important;

  textarea {
    min-height: 25px;
  }
}

select.form-control:not([size]):not([multiple]) {
  height: calc(1.39rem + 4px);
}

.m-tabs-line .m-tabs__item {
  margin-right: 20px;
}

.nav-link.m-tabs__link {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.48px;
  color: var(--primary-color);

  &.ip-link {
    display: inline-block;
    font-size: 14px;
    padding-left: 0;
    color: #6a6a6a !important;

    .ip-name {
      color: var(--primary-color);
      font-size: 20px;
      font-weight: 400;
    }

    .ip-value {
      color: #777;
    }
  }
}

.m-tabs__item {
  &.ip-item {
    padding: 0 10px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.07);
    }
  }
}

.ip-list {
  padding: 0;
}

.noChargingFound {
  color: #161B2B;
  font-size: 18px;
  padding: 0 50px;
}

input[type="text"]:not(.browser-default):disabled {
  border-bottom: none;
  color: #fff;

  &+label {
    color: #fff;
  }
}

.form-control {
  border-radius: 2px;
  background-color: #40424d;

  &:focus {
    background: transparent;
    color: #fff;
    border-color: #fff;
  }
}

.m-portlet__head-caption {
  margin-top: 8px;
}

.m-portlet.smu_pvi {
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.m-portlet .m-portlet__head.header-table {
  border: none;
}

.m-portlet.m-portlet--tabs .m-portlet__head {
  padding: 0 3.9rem;
}

.m-portlet__head {
  background-color: #25272d;
  border-bottom: none !important;

  .table-navig {
    background-color: #2b2d34;
    height: auto;
    padding: 0;

    li:first-child {
      margin-left: 29px !important;
    }
  }
}

.react-tabs__tab-panel.react-tabs__tab-panel--selected {
  position: relative;
}

.form-control.m-input.m-input--solid.search {
  height: 37px;
  border-radius: 2px;
  background-color: #f8f8f8;
  border-color: #b6b7b9;
  line-height: 37px;
}

.m-grid__item.m-grid__item--fluid.m-grid.m-grid--hor.m-login.m-login--signin.m-login--2.m-login-2--skin-1 {
  overflow: hidden;
}

.input-field-for-ch-pass {
  margin-bottom: 5px !important;
  position: relative;
}

.form-group.m-form__group.input-field.input-field-for-ch-pass {
  margin-bottom: 20px !important;
}

.m-checkbox.m-checkbox--state-warning>input:checked~span {
  border: 1px solid;
  background-color: var(--primary-color);
}

.fa.fa-caret-right {
  color: #898b96;
}

.fa.fa-caret-down {
  color: var(--primary-color);
}

.m-checkbox.m-checkbox--state-warning>span {
  border: 1px solid #bdc3d4;
}

.m-checkbox.m-checkbox--state-warning>span:after {
  border-top-color: #fff !important;
  border-top-style: solid;
  border-top-width: initial;
  border-right-color: #fff !important;
  border-right-style: solid;
  border-right-width: initial;
  border-bottom-color: #fff !important;
  border-bottom-style: solid;
  border-bottom-width: initial;
  border-left-color: #fff !important;
  border-left-style: solid;
  border-left-width: initial;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
}

.m--font-success {
  color: #4ccd37 !important;
}

.m-badge.m-badge--success {
  background-color: #4ccd37;
}

.m-card-user__name {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 219px;
}

.m-checkbox.m-checkbox--brand.m-checkbox--solid>span:after {
  border: solid #25272d;
}

.error_status_yes {
  color: #f4516c !important;

  &.warning {
    color: $deviceActivityWarningColor !important;
  }
}

.update-name-inp {
  border-radius: 2px 0 0 2px;
  border: 1px solid #8f939b !important;
  padding: 3px 18px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.48px;
  line-height: 30px;
  width: 65%;
  min-width: 240px;

  @media screen and (max-width: 500px) {
    width: 100%;
    min-width: 100%;
  }
}

.sear-sel-inps {
  margin-top: 31px;
}

.pop-up-form {
  padding: 0 45px;
}

.modal-content {
  background-color: #f8f8f8;
  overflow: visible;

  .modal-header {
    padding: 0 26px;
    border-bottom: none;
    background-color: #fff;

    .modal-title {
      color: #161b2b;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0.72px;
      margin: 40px 38px 35px 38px;
    }
  }

  .modal-body {
    padding-bottom: 0;
    background-color: #f8f8f8;

    .info {
      color: #161B2B;
    }

    .text-confirmation {
      color: #9e9e9e;
      font-size: 18px;
      font-weight: 400;
      padding: 20px 50px;
    }

    .text-confirmation-detailed {
      font-style: italic;
      color: #9e9e9e;
      font-size: 16px;
      padding: 0px 50px 24px;
    }
  }

  .modal-footer {
    padding: 14px 62px 25px 62px;
    background-color: #f8f8f8;
    padding-top: 20px !important;
    border-top: none !important;
    border: none;

    &.highlighted {
      margin-top: 10px;
      border-top: 1px solid rgba(22, 27, 43, 0.10) !important;
    }
  }
}

.settings-header-block {
  background-color: #f8f8f8 !important;
  align-items: center;
}

.m-tabs-line.m-tabs-line--danger a.m-tabs__link.active,
.m-tabs-line.m-tabs-line--danger a.m-tabs__link:hover {
  color: var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
}

.user-name {
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.51px;
  margin: 12px 0;
}

.nav.nav-pills .nav-item {
  margin-left: 0;
}

.active-datatable-row {
  background-color: #33363e;
}

.nav.nav-pills.nav-fill {
  border-bottom: 2px solid #fff;
}

.la-info-circle {
  color: var(--primary-color);
  cursor: pointer;

  &.inform-web {
    font-size: 25px;
    text-decoration: none;
  }
}

.warning-par {
  margin-top: 47px;
  font-weight: 400;

  i {
    font-weight: 500;
  }
}

.tooltipInsideRow {
  margin-top: 30px;
}

.tooltipInsideRowHeader {
  margin-top: 5px;
}

.tooltip-inform {
  position: relative;
  display: inline-block;

  .tooltiptext-inform {
    visibility: hidden;
    width: 120px;
    background-color: #fff;
    color: black;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 105%;
  }
}

.tooltip-inform:hover .tooltiptext-inform {
  visibility: visible;
}

.dashboard-link {
  text-transform: capitalize;
  color: var(--primary-color) !important;
  font-size: 14px;
  font-weight: 400;
  width: auto;
  position: absolute;
}

.sub-navigation-analysis {
  display: flex;
  gap: 10px;
  top: 34px;
  right: 2%;

  .link {
    @extend .dashboard-link;
    position: static;
    line-height: 2;
  }
}


.city-zip {
  margin: 0;
}

.city-inp {
  margin: 0 !important;
}

.zip-inp {
  margin-right: 0 !important;
}

.nav-settings {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #ffffff;
}

.m--font-bold.signal {
  font-weight: 400;
  font-size: 16px;
}

.inputCustomWarning {
  padding: 0 !important;
  font-size: 11px !important;
  margin: 4px 0 0 !important;

  &.m-0 {
    margin: 0 !important;
  }

  &.pl-2 {
    padding-left: 10px !important;
  }
}

.textarea-custom-warning {
  min-height: 16.5px;
  font-size: 11px;
  font-weight: 400;
  color: #f4516c;
  margin: 4px 0 0;
  padding: 0;
}

.settings-user-prof {
  background-color: #f8f8f8;
}

.set-prof-head {
  color: #161b2b;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.48px;
  margin-bottom: 0;
}

label.label-for-sett {
  font-size: 16px;
  color: #c1c1c1;
}

div [class*="MuiPopover-paper-"] {
  z-index: 1000;
}

.form-control.m-input--air {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.cont-sett-user-lang-select.seet {
  height: 75px;
}

select.form-control.sel-seet:not([size]):not([multiple]) {
  height: calc(1.39rem + 17px);
}

.label_for_select.label-for-sett {
  font-size: 13px;
}

.cont-sett-user-lang-select {
  position: relative;
}

.sett-user-lang-select {
  position: absolute;
  top: 23px;
}

.settings-user-details {
  margin-top: 92px;
}

.user-photo img {
  border: 1px solid #5e6774;
  border-radius: 50%;
  background-color: #25272d;
  width: 135px;
  height: 135px;
}

.email-text-td:hover {
  text-decoration: underline;
}

.react-tabs__tab-list {
  padding: 0;
  margin: 0;
  border: 1px solid #40424d;
  position: relative;
}

.react-tabs__tab {
  display: inline-block;
}

.load-guard-tab-item {
  margin-right: 190px;
}

.react-tabs__tab .tabs-cont {
  border: none;
}

.nav-item.m-tabs__item:focus {
  outline: none;
}

.nav.nav-tabs.m-tabs-line.m-tabs-line--danger.m-tabs-line--2x.m-tabs-line--right.nav-tab-links {
  outline: none;
}

:focus {
  outline: none;
}

.react-tabs__tab .nav-tabs .nav-item {
  margin-bottom: -12px;
}

.react-tabs__tab-list {
  border: 1px solid #f0f0f0;
  background-color: #fff;
}

.remem-checkbox {
  opacity: 1;
  color: #4a4a4a !important;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.42px;
}

.m-tabs-line {
  border-bottom: none;

  a.m-tabs__link {
    color: #fff;
    letter-spacing: 0.48px;
    font-weight: 500;
    font-size: 18px;
  }
}

.la.la-user-comp {
  font-size: 1.6rem;
  border: 1px solid #3e404c;
  padding: 3px;
  border-radius: 50%;
}

.nav.nav-tabs {
  margin-bottom: 0;
}

.table-details-user-nifo.yell {
  color: var(--primary-color);
}

.table-details-user-nifo-value {
  font-weight: 500;
}

.table-details-user-nifo-value-type {
  color: #8d9199;
  font-weight: 400;
  font-size: 16px;
}

.tarif-header {
  color: #8f939b;
  font-weight: 500;
  letter-spacing: 0.48px;
  font-size: 16px;
}

.tabs-cont {
  margin: 0;
  border: 1px solid #40424d;
  border-bottom: none;
  box-sizing: border-box;
}

.nav-tab-links {
  border-bottom: none;
}

.gener-sett-soft-container {
  margin-top: 27px;
  padding-bottom: 0;

  i.la-edit {
    color: var(--primary-color) !important;
  }
}

.general-sett-modal-label {
  color: #161b2b;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.48px;
}

.gener-sett-header {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.48px;
  margin-bottom: 27px;
  margin-top: 10px;
}

.title-create-acc {
  margin-bottom: 102px;
}

.nav.nav-pills.nav-tabs-items {
  margin-bottom: 0;
}

.m-badge {
  color: #161b2b !important;
}

.m-badge.m-badge--metal.gener-sett-soft-select {
  background-color: transparent;
}

.table-comp-mail {
  color: #68b0ee !important;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.48px;
}

.company-logo img {
  padding: 21px;
  border: 1px solid #5e6774;
  border-radius: 50%;
  background-color: #25272d;
}

.company-details-table .company-detail-td {
  padding: 0 100px 0 25px;
}

.smu-select-sp {
  font-size: 16px;
  color: #8f939b !important;
  font-weight: 500;
  letter-spacing: 0.48px;
}

.smu-select-sp-num {
  font-size: 16px;
  color: #4a4a4a !important;
  font-weight: 500;
  letter-spacing: 0.48px;
}

.input-filed-flags-select {
  height: 48px !important;
  border-bottom: 1px solid #9e9e9e;
  margin-bottom: 25px;
}

.flag-options {
  width: 100%;
}

.flag-option.has-label {
  color: black;
}

.input-field-street {
  margin-bottom: 0 !important;
}

.MuiFormLabel-root-10 {
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.39px;
}

.input-field-checkbox-terms {
  height: 14px !important;
}

.simplePopoverRoot {
  position: absolute !important;
}

.simplePopoverParent {
  overflow: visible !important;
  margin-top: 20px;
  z-index: 1000;
  background: transparent !important;
}

.settings-header-block.device-top-bar {
  min-height: auto;
  height: 62px;
}

.nav-tabs .nav-item {
  margin-bottom: -6px;
}

.m-tabs-line.m-tabs-line--2x a.m-tabs__link {
  border-bottom-width: 4px !important;
}

.input-field.input-field-sm {
  margin-bottom: 9px !important;
}

.email-detail-table a {
  cursor: pointer;
  color: #68b0ee;
}

.switch-tooltip {
  font-size: 20px;
  float: right;
  margin: 0 0 -45px;
  margin-top: 16px;
  position: relative;
  z-index: 10;

  &.select {
    margin: 18px 24px -50px 5px;
  }
}

.switch-tooltip.tooltipInSelect {
  display: inline-block;
  font-size: 16px;
  float: none;
  margin: -5px 6px -3px;
}

.progress .la.la-info-circle {
  font-size: 16px;
  color: #fff;
  line-height: 19px;
}

.switch-tooltip+.m-checkbox.remem-checkbox {
  padding-right: 26px;
}

.modal-dialog {
  .Select-control {

    .Select-value,
    .Select-placeholder {
      width: 350px;
    }
  }

  .switch-tooltip.select {
    margin-top: 20px;
  }
}

.avatar-logo {
  width: 135px;
  height: 100%;
}

.control-panel {
  margin: 30px 0 0 0;
}

.btn.m-btn--pill.m-btn--air.btn-secondary.btn-table-inst {
  color: var(--primary-color) !important;
}

.date-dashb {
  color: #161b2b;
  line-height: 20px;
  font-weight: 300;
  margin-right: 14px;
  font-size: 16px;
}

@media (max-width: 1400px) {
  .user-name-topbar {
    max-width: 147px;
  }
}

@media (max-width: 1200px) {
  .gener-sett-inside-cont {
    margin-top: 15px;
  }

  .gener-sett-soft {
    height: calc(100% - 20px);
  }

  .gener-sett-inside-cont.smu-set-bl {
    border: none;
    border-right: 1px solid #40424d;
  }

  .user-name-topbar {
    display: none;
    max-width: 147px;
  }

  .header-det-energy-stat {
    font-size: 1em;
  }

  .det-energy-stat-kwh {
    font-size: 15px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }

  .gener-sett-soft {
    height: calc(100% - 20px);
  }

  .car-charger-input {
    width: 65%;
    display: inline-block !important;
  }
}

@media (max-width: 1034px) {
  .nav-buttons-dashb:first-child .navig-btn-dashb {
    margin-right: 0;
  }
}

@media (max-width: 992px) {
  .gener-sett-inside-cont.smu-set-bl {
    border: none;
    border-bottom: 1px solid #40424d;
  }

  .gener-sett-inside-cont.smu-set-bl.smu-set-bl-last {
    border-bottom: none;
  }

  .company-details-table .company-detail-td {
    padding: 0 10px 0 25px;
  }

  .car-charger-select {
    margin-top: 25px;
  }

  .nav-buttons-dashb {
    padding: 0 0 0 15px;

    &:last-child {
      padding-right: 5px;
    }
  }

  .liveDataStatus {
    margin: 0;
  }

  .date-dashb {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .dashboardApp .settings-header-block {
    min-height: 201px;
    align-items: center;
  }
}

@media (max-width: 770px) {
  .det-energy-stat {
    font-size: 20px;
    font-weight: 400;
  }
}

@media (max-width: 768px) {

  .m-login.m-login--2.m-login-2--skin-1 .m-login__container .m-login__form .m-login__form-sub .m-link,
  .m-login.m-login--2.m-login-2--skin-1 .m-login__container .m-login__form .m-login__form-sub .m-checkbox {
    font-size: 12px;
  }

  .m-login.m-login--2.m-login-2--skin-1 .m-login__container .m-login__form .m-login__form-sub .m-checkbox.terms {
    font-size: 12px;
  }

  .m-login.m-login--2.m-login-2--skin-1 .m-login__container .m-login__form .m-login__form-action .m-login__btn.forgot-button {
    font-size: 12px;
  }

  .btn.m-btn--pill.m-btn--air.btn-secondary.btn-table-inst {
    margin-top: 0;
  }

  .thank-you-cont {
    margin-top: 30px;
  }
}

.note-field {
  width: 100%;
  height: auto;
  min-height: 36px;
  margin: 6px 0 0 0;
  padding: 7px;
  color: #161b2b;
  border: 1px solid #9e9e9e;
  background-color: transparent;
  resize: none;

  &::placeholder {
    color: #9e9e9e;
  }
}

.max-width-550 {
  max-width: 550px;
}

@media (max-width: 1019px) {
  .car-charger-img {
    display: none;
  }
}

@media (max-width: 723px) and (min-width: 660px) {
  .header-en-value {
    font-size: 14px;
    margin-bottom: 0px;
  }

  .en-value {
    font-size: 33px;
  }

  .car-charger-select {
    margin-top: 12px;

    .form-group {
      font-size: 12px;

      select {
        height: 1.8rem;
      }
    }
  }
}

@media (max-width: 1019px) and (min-width: 724px) {
  .header-en-value {
    font-size: 17px;
    margin-bottom: 1px;
  }

  .en-value {
    font-size: 34px;
  }

  .car-charger-select {
    margin-top: 16px;

    .form-group {
      font-size: 14px;
    }
  }

  select.form-control.car-charger-input:not([size]):not([multiple]) {
    height: 3rem;
    cursor: pointer;
  }
}

@media (max-width: 576px) {
  .m-grid__item.m-grid__item--fluid.thank-you-cont p {
    font-size: 15px;
  }

  .btn.m-btn--pill.m-btn--air.btn-secondary.btn-table-inst.btn-save-chan {
    margin-top: 0;
  }

  .modal-dialog {
    max-width: 530px;
  }

  .header-en-value {
    font-size: 1.2em;
  }

  .en-value {
    font-size: 3em;
  }

  .header-progr-bar-dashb {
    display: none;
  }
}

@media (max-width: 490px) {
  .nav.nav-pills.nav-fill {
    border-bottom: 2px solid transparent;
  }

  .navig-btn-dashb {
    width: 37px;
  }
}

#m_login {
  position: relative;
  height: 100%;
  width: 100%;

  .customTextField {
    label[data-shrink="true"] {
      color: #034675 !important;
    }

    label[data-shrink="false"] {
      color: #4d4c4c !important;
    }

    .inputRoot:before {
      color: #4d4c4c !important;
    }

    .inputRoot:after {
      color: #034675 !important;
    }
  }

  .m-checkbox.m-checkbox--state-warning>span {
    border: 1px solid #4d4c4c;
  }
}

#m_login:before {
  content: "";
  background: url(../../../public/images/bg-img.png) bottom;
  /* linear-gradient(rgba(0,0,0,.1), rgba(0,0,0,.1));
     */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.7;
}

.options-list {
  padding: 0;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #161B2B;
  gap: 12px;

  .options-item {
    cursor: pointer;
    padding: 13px 24px 13px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16);

    &:hover {
      background: #f0f0f0;

    }
  }
}

.checkbox-react-table {
  &>input {
    display: block;
    position: relative !important;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.48px;
    top: 11px !important;
    left: 12px !important;

    &:after {
      content: "";
      top: 2px;
      left: 5px;
      width: 5px;
      height: 10px;
      transform: rotate(45deg);
      border: solid #25272d;
      border-width: 0 2px 2px 0;
      display: block;
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    &:before {
      content: "";
      background-color: #40424d;
      height: 18px;
      width: 18px;
      position: absolute;
      border-radius: 3px;
      top: -1px;
      left: -2px;
      cursor: pointer;
    }
  }

  &>input:checked:before {
    background-color: var(--primary-color);
  }

  &>input:checked:after {
    opacity: 1;
  }
}

.checkbox-react-table-all {
  input {
    display: block;
    position: relative !important;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.48px;
    top: 11px !important;
    left: 12px !important;

    &:after {
      content: "";
      top: 2px;
      left: 5px;
      width: 5px;
      height: 10px;
      transform: rotate(45deg);
      border: solid #25272d;
      border-width: 0 2px 2px 0;
      display: block;
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    &:before {
      content: "";
      background-color: #40424d;
      height: 18px;
      width: 18px;
      position: absolute;
      border-radius: 3px;
      top: -1px;
      left: -2px;
      cursor: pointer;
    }
  }

  input:checked:before {
    background-color: var(--primary-color);
  }

  input:checked:after {
    opacity: 1;
  }
}