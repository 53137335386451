$deviceActivityErrorColor: #ff1c3c;
$deviceActivityWarningColor: #ff9e22;

.table-devices-body {
  padding-bottom: 20px;
  padding-top: 15px;
}

.gener-sett-soft {
  border: 1px solid #40424d;
  padding: 31px;

  .admin-user-det {
    padding: 0 20px 0 0;
  }
}

.gener-sett-inside-cont.smu-set-bl.gener-sett-soft {
  margin-bottom: 38px;
}

.gener-sett-header {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.48px;
  margin-bottom: 27px;
  margin-top: 10px;
}

.user-details-container {
  background-color: #f8f8f8;
}

.export-button {
  .download-icon {
    margin-right: 5px;
  }
}

@mixin free {
  background-color: rgba(235, 178, 0, 0.09);
  width: fit-content;
  color: rgb(235, 178, 0);
}

@mixin basic {
  background-color: rgba(5, 183, 173, 0.09);
  width: fit-content;
  color: rgb(5, 183, 173);
}

@mixin pro {
  background-color: rgba(0, 119, 217, 0.09);
  width: fit-content;
  color: rgb(0, 119, 217);
}

.plan-type {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  border: none;
  border-radius: 136px;

  &.code-10,
  &.Free {
    padding: 0 8px;
    @include free;
  }

  &.code-11,
  &.Basic {
    padding: 0 8px;
    @include basic;
  }

  &.code-12,
  &.Pro {
    padding: 0 8px;
    @include pro;
  }
}

.table-tariff-header {
  width: 220px !important;

  .tarif-header {
    width: 400px !important;
  }
}

.width-300 {
  width: 300px !important;
}

.errors-container {
  display: inline-flex;
  justify-content: flex-start;
  gap: 5px;
}

.error_status_yes {
  display: flex;
  align-items: center;
  height: 100%;
  width: auto;
  text-align: center;

  .error_wrapper {
    font-size: 14px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $deviceActivityErrorColor;
    border: 1px solid $deviceActivityErrorColor;
    border-radius: 15px;
    width: 42px;
    height: min-content;
    line-height: 1.4;
    padding-top: 1px;
    cursor: pointer;
  }

  &.device-activity-code-1 {
    .error_wrapper {
      color: $deviceActivityWarningColor;
      border: 1px solid $deviceActivityWarningColor;
    }
  }

  &.device-activity-code-2,
  &.device-activity-code-3 {
    display: none;
  }
}

.error-tooltip_wrapper {
  padding: 12px !important;
  background-color: #F8F8F8 !important;
  color: #161B2B !important;

  p {
    margin: 0;
    padding: 0;
  }

  .error-title {
    font-size: 14px !important;
    font-weight: 500;
    padding-bottom: 10px;
  }

  .error-subtitle {
    font-size: 12px !important;
  }
}

.gener-sett-soft-select {
  border: none;
  font-size: 15px !important;
  font-weight: 400 !important;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px !important;
  text-align: left !important;
}

.full-user-name {
  display: block;
  width: 140px !important;
  text-overflow: ellipsis;
  overflow: hidden;
}

.load-management {
  margin: 15px 0 !important;
  display: flex;
  line-height: 20px;
  gap: 3px;
}

.feed-in-checkbox {
  &.m-checkbox.m-checkbox--state-warning>span {
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
  }
}

.load-management-label {
  margin: 0 !important;
  color: #8f939b !important;
  font-size: 16px !important;
  font-weight: 500;
  white-space: nowrap;

  &.m-checkbox.m-checkbox--state-warning>span {
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
  }
}

.load-management>i {
  align-self: center;
  font-size: 16px;
}

td.m-datatable__cell.table-tariff-header {
  width: 220px;

  span.tarif-header {
    width: 400px;
  }
}

.m-datatable__cell {
  span.w-300 {
    width: 300px;
  }

  span.m-badge.gener-sett-soft-select.m-badge--metal.m-badge--wide {
    border: none;
    font-size: 15px;
    font-weight: 400;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 140px;
    text-align: left;
  }

  div.table-details-user-nifo {
    display: block;
    width: 140px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

a.back-to-dashboard {
  height: 28px !important;
  text-transform: capitalize;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #fff !important;
  box-shadow: none !important;
  line-height: 0.5 !important;
}

.subscription-block-info {
  display: flex;

  .plan-type {
    margin: auto 0;
    margin-right: 5px !important;
  }

  .m-btn--hover-accent {
    .subsection-icon {
      height: 0px !important;
      padding-bottom: 20px !important;
      margin-left: 5px;

      &:hover {
        background: #ffb822 !important;
        border-color: #ffb822 !important;
      }
    }
  }
}


.tariff-tabs {
  .MuiButton-colorSecondary {
    background: #161B2B1A !important;
    color: #161B2B;
  }


  .MuiButton-colorPrimary {
    &:hover {
      background: var(--primary-color) !important;
    }
  }
}