.cancel-button {
    display: inline-block;
    transition: none;
    text-align: center;
    background: transparent;
    white-space: nowrap;

    width: auto;
    height: 39px;
    padding: 0.65rem 1rem;
    margin: 0;

    border: 1px solid #0000007F;
    border-radius: 60px;

    font-size: 16px;
    font-weight: 300;

    line-height: 0.9;
    opacity: 0.9;
    color: #0000007F;
    letter-spacing: 0.64px;
    cursor: pointer;

    -webkit-box-shadow: 0 3px 20px 0 rgba(113, 106, 202, .17);
    -moz-box-shadow: 0 3px 20px 0 rgba(113, 106, 202, .17);
    box-shadow: 0 3px 20px 0 rgba(113, 106, 202, .17);

    &:hover:not(:disabled):not(.active) {
        background-color: #9e9e9e !important;
        border: 1px solid #9e9e9e !important;
        color: #fff !important;
    }

    &:focus {
        background-color: #9e9e9e !important;
        border: 1px solid #9e9e9e !important;
        color: #fff !important;
    }
}