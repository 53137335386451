.m-login__log {
  margin: 8px 0 9px 0;
}
.topbar-dashb .topBarLogo {
  max-height: 45px;
  margin-top: -8px;
  margin-bottom: -9px;
}

.topBarLogo {
  max-height: 42px;
}
