.cloud-status-message {
  width: 100%;
  height: auto;
  min-height: 36px;
  margin: 6px 0 0 0;
  padding: 7px;
  color: #161b2b;
  border: 1px solid #5e6774;
  background-color: transparent;
  resize: none;
  overflow: none;
  max-height: 300px;

  &::placeholder {
    color: #161b2b7f;
  }
}

.cloudStatusModal {
  .modal-body {
    padding-top: 10px !important;
  }

  .modal-footer {
    border-top: 1px solid #40424d !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
  }
}

#CloudStatusForm {
  background-color: transparent;
  .MuiTabs-root {
    color: #161b2b7f;
    padding: 0;
    margin-bottom: 15px;
    min-height: 30px;
    font-weight: 400;
  }
  .MuiButtonBase-root {
    color: #161b2b7f !important;
    opacity: 1;
    font-size: 15px;
    padding-bottom: 7px;
  }

  .MuiTabs-flexContainer {
    border-bottom: 2px solid rgba(255, 255, 255, 0.3);
  }

  .Mui-selected {
    color: #161b2b !important;
  }

  .MuiTabs-indicator {
    background-color: var(--primary-color);
    height: 2px;
  }
  .tab-wrapper {
    border: 1px solid #40424d;
    border-radius: 7px;
  }

  .MuiTab-root {
    min-height: 30px !important;
    width: 50%;
    color: rgba(255, 255, 255, 0.3);
    text-transform: capitalize;
  }
}
