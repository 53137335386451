@keyframes showNoAccessPaymentsWarning {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

.center-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  .payments-warning-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    box-sizing: border-box;
    padding: 25px;

    .payments-warning-message-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 50px;

      .payments-warning-message {
        font-size: 14px;
        font-weight: 500;
        color: #4a4a4a;
        text-align: center;
      }
    }
    .payments-redirect-message-container {
      display: flex;
      flex-direction: column;
      margin: 0;

      .payments-redirect-message,
      .payments-redirect-countdown {
        font-weight: 400;
        color: rgba(28, 34, 54, 0.5);
        text-align: center;
        margin: 0;
      }

      .payments-redirect-message {
        font-size: 12px;
      }

      .payments-redirect-countdown {
        font-size: 14px;
      }
    }
  }
}
