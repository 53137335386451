.car-modal {
  .modal-body {
    .tabs-row {
      margin-bottom: 20px;
    }
    
    .MuiTabs-root {
      min-height: unset !important;
      max-height: 40px;
    }

    .MuiTabs-flexContainer {
      gap: 25px;
    }

    .MuiButtonBase-root {
      padding: 0;
      opacity: 1;
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      min-width: fit-content;
      cursor: default;
    }

    .Mui-selected {
      color: var(--primary-color) !important;
    }

    .MuiTabs-indicator {
      background-color: var(--primary-color);
      height: 2px;
    }

    .MuiTab-root {
      color: #161B2B;
      text-transform: capitalize;
    }

    .validation-error {
      color: #f4516c;
      font-size: 11px;
    }
  }

  .modal-footer {
    padding: 14px 42px 25px 42px !important;
    display: flex;
    justify-content: space-between;

    .buttons-wrapper {
      display: flex;
      margin-left: auto;
      gap: 20px;
    }
  }
}

.tab-3 {
  min-width: 630px;
}

.tab-4 {
  min-width: 700px;
}
