input.datepicker-input {
  font-size: 18px !important;
  line-height: 21px;
  padding: 8.5px 18.5px;
  border: none !important;

  &:hover,
  &:focus {
    border: none !important;
  }
}

.datepicker-input-field {
  .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      border-color: #161b2b;
    }

    &:hover,
    &:focus {
      .MuiOutlinedInput-notchedOutline,
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--primary-color);
      }
    }
  }

  .MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: var(--primary-color);
    }

    &:hover,
    &:focus {
      .MuiOutlinedInput-notchedOutline,
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--primary-color);
      }
    }
  }

  .inputRoot::after {
    border: none;
  }
}

.custom-date-input-warning {
  white-space: nowrap;
}
