.invited-users-wrapper {
  .rt-noData.customNoData {
    top: 95px;
    left: calc(50% + 15px);
  }

  .rt-table {
    background: transparent;
    border: 1px solid #161B2B1A;
  }
  .react-tabs__tab-list {
    border: none;
    background-color: transparent;
  }
}
